import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vetical-align: middle;
`

const StyledLink = styled(Link)`
  display: flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;

  svg {
    &:hover {
      opacity: 0.6;
    }
  }
`

export default ({ currentPage, totalPages, pathPrefix, firstPagePath }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages

  if (isFirst && isLast) {
    return null
  }

  const prevPage =
    currentPage === 2 ? firstPagePath : `${pathPrefix}/page/${currentPage - 1}`
  const nextPage = `${pathPrefix}/page/${currentPage + 1}`

  return (
    <Pagination>
      {!isFirst && (
        <StyledLink to={prevPage}>
          <FontAwesomeIcon icon={['fa', 'chevron-left']} />
        </StyledLink>
      )}
      {currentPage} / {totalPages}
      {!isLast && (
        <StyledLink to={nextPage}>
          <FontAwesomeIcon icon={['fa', 'chevron-right']} />
        </StyledLink>
      )}
    </Pagination>
  )
}
