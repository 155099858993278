import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from '@emotion/styled'

const RoundedImage = styled(Image)`
  border-radius: 30px;
`

export default ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gesomori-icon.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <RoundedImage
      className={className}
      fixed={data.file.childImageSharp.fixed}
      alt="gesomori"
    />
  )
}
