import React from "react"
import styled from "@emotion/styled"

const Footer = styled.footer`
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
`

export default () => <Footer>© 2019 gesomori</Footer>
