import React from 'react'
import styled from '@emotion/styled'

const Card = styled.div`
  background-color: #fff;
  padding: 32px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: ${({ hovereffect }) =>
      hovereffect && '0 8px 12px rgba(0, 0, 0, 0.25);'};
  }

  @media (max-width: 560px) {
    padding: 32px 16px;
  }
`

export default ({ className, hovereffect, children }) => {
  return (
    <Card className={className} hovereffect={hovereffect}>
      {children}
    </Card>
  )
}
