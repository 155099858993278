import React from 'react'
import { css } from '@emotion/core'
import Tag from '../atoms/tag'

const tagStyle = css`
  display: inline-block;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
`

const TagList = ({ tags, className }) => {
  if (!tags || tags.length === 0) {
    return null
  }

  return (
    <div className={className}>
      {tags &&
        tags.map(tag => {
          return <Tag key={tag.slug} tag={tag} css={tagStyle} />
        })}
    </div>
  )
}

export default TagList
