import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import Card from '../molecules/card'
import TagList from '../molecules/tag-list'
import Date from '../atoms/date'

const headerCardStyle = css`
  padding: 16px 32px;
  margin-bottom: 24px;

  h1 {
    font-size: 20px;
    margin: 0;
  }
`

const cardStyle = css`
  margin-bottom: 24px;

  h2 {
    a {
      color: #333;

      &:hover {
        color: #4588d2;
      }
    }
  }
`

const dateStyle = css`
  margin-bottom: 12px;
`

const tagListStyle = css`
  margin-bottom: 24px;
`

const readMoreLinkStyle = css`
  color: #4588d2;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #4588d2;
  }
`

export default ({ headerTitle, posts }) => (
  <Fragment>
    {headerTitle && (
      <Card css={headerCardStyle}>
        <h1>{headerTitle}</h1>
      </Card>
    )}
    {posts.map(({ node }) => (
      <Card key={node.id} css={cardStyle}>
        <article>
          <Date css={dateStyle} date={node.frontmatter.date} />
          <h2>
            <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </h2>
          <TagList tags={node.frontmatter.tags} css={tagListStyle} />
          <p>{node.excerpt}</p>
          <Link to={node.fields.slug} css={readMoreLinkStyle}>
            続きを読む
          </Link>
        </article>
      </Card>
    ))}
  </Fragment>
)
