import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from '../atoms/avatar'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Bio = styled.div`
  display: flex;
  margin-bottom: 24px;
`

const avatarStyle = css`
  margin-right: 16px;
`

const usernameStyle = css`
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
`

const socialButtonsStyle = css`
  display: flex;
  font-size: 24px;

  a {
    display: inline-block;
    margin-right: 20px;

    &:hover {
      opacity: 0.6;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export default ({ className }) => (
  <Bio className={className}>
    <Avatar css={avatarStyle} />
    <div>
      <div css={usernameStyle}>gesomori</div>
      <div css={socialButtonsStyle}>
        <a
          href="https://github.com/gesomori"
          target="_blank"
          rel="noreferrer noopener"
          area-label="GitHub"
        >
          <FontAwesomeIcon icon={['fab', 'github']} />
        </a>
        <a
          href="https://twitter.com/gesomori"
          target="_blank"
          rel="noreferrer noopener"
          area-label="Twitter"
        >
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </a>
        <a
          href="https://www.facebook.com/somori.takuya"
          target="_blank"
          rel="noreferrer noopener"
          area-label="Facebook"
        >
          <FontAwesomeIcon icon={['fab', 'facebook-square']} />
        </a>
      </div>
    </div>
  </Bio>
)
