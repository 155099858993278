import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

const Date = styled.span`
  display: inline-block;
  color: #999;
`

export default ({ className, date }) => {
  const formattedDate = dayjs(date).format('YYYY/MM/DD')

  return <Date css={className}>{formattedDate}</Date>
}
