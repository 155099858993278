import React from 'react'
import styled from '@emotion/styled'

const Column2 = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 800px 300px;
  margin: 0 auto 24px;
  padding: 0 16px;
  box-sizing: border-box;
  flex: 1;
  @media (max-width: 959px) {
    max-width: 800px;
    padding: 0 16px;
    grid-template-columns: minmax(0, 1fr);
  }
  @media (max-width: 560px) {
    padding: 0 8px;
  }
`
export default ({ children }) => <Column2>{children}</Column2>
