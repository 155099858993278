import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const Tag = styled.span`
  dispaly: inline-block;
  background-color: #45a6ff;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    opacity: 0.6;
  }
`

export default ({ tag, className }) => (
  <Link to={`/blog/tags/${tag.slug}`} className={className}>
    <Tag>{tag.id}</Tag>
  </Link>
)
