import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Card from '../molecules/card'
import Bio from '../molecules/bio'
import TagList from '../molecules/tag-list'

const StyledCard = styled(Card)`
  margin-bottom: 24px;
`

const Heading = styled.span`
  display: block;
  margin-bottom: 24px;
  font-weight: bold;
  color: #586069;
`

const bioStyle = css`
  display: flex;
  margin-bottom: 24px;
`

const tagListStyle = css`
  a {
    margin-bottom: 16px;
  }
`

const TagCloud = styled.div`
  margin-bottom: -16px;
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      tagsGroup: allMarkdownRemark {
        group(field: frontmatter___tags___id) {
          fieldValue
          totalCount
        }
      }
      tagsInfo: allTagsJson {
        nodes {
          id
          slug
        }
      }
    }
  `)

  const sortedTagsGroup = data.tagsGroup.group.sort(
    (a, b) => b.totalCount - a.totalCount
  )

  const tags = []
  sortedTagsGroup.forEach(tagGroup => {
    const tag = data.tagsInfo.nodes.find(({ id }) => id === tagGroup.fieldValue)
    tag['totalCount'] = tagGroup.totalCount
    tags.push(tag)
  })

  return (
    <aside>
      <StyledCard>
        <Heading>PROFILE</Heading>
        <Bio css={bioStyle} />
        <p>
          Webアプリケーションエンジニアです。サーバーサイドは主にRuby on
          Railsで開発しています。フロントエンドは、ReactやVue.jsなどを扱っています。
        </p>
      </StyledCard>
      <StyledCard>
        <Heading>TAGS</Heading>
        <TagCloud>
          <TagList tags={tags} css={tagListStyle} />
        </TagCloud>
      </StyledCard>
    </aside>
  )
}
