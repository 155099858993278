import React from 'react'
import { Link } from 'gatsby'
import IkaproLogo from '../atoms/ikapro-logo'
import styled from '@emotion/styled'

const Header = styled.header`
  display: flex;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  color: red;
  @media (max-width: 959px) {
    height: 120px;
  }
  @media (max-width: 560px) {
    height: 108px;
  }
`

export default () => (
  <Header>
    <Link to="/" area-label="ikapro">
      <IkaproLogo />
    </Link>
  </Header>
)
