import React from 'react'
import styled from '@emotion/styled'

const Text = styled.section`
  a {
    color: #4588d2;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: #4588d2;
      text-decoration: underline;
    }
  }

  h2,
  h3 {
    margin: 0 0 28px 0;
  }
`

export default ({ html, children, className }) => {
  return html ? (
    <Text className={className} dangerouslySetInnerHTML={{ __html: html }} />
  ) : (
    <Text className={className}>{children}</Text>
  )
}
