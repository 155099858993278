import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export default ({
  title,
  titleTemplate,
  description,
  canonical,
  thumbnail,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
          siteUrl
          description
          twitterUsername
          image
        }
      }
    }
  `)

  const metaTitle =
    title || `${site.siteMetadata.siteName} - ${site.siteMetadata.description}`
  const metaDescription = description || site.siteMetadata.description
  const metaThumbnail = thumbnail || site.siteMetadata.image

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={titleTemplate || `%s - ${site.siteMetadata.siteName}`}
      htmlAttributes={{
        lang: 'ja',
      }}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'lang',
          content: site.siteMetadata.lang,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: canonical,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `${site.siteMetadata.siteUrl}${metaThumbnail}`,
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.siteName,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: `@${site.siteMetadata.twitterUsername}`,
        },
        {
          name: 'twitter:creator',
          content: `@${site.siteMetadata.twitterUsername}`,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]}
      link={[
        {
          rel: `canonical`,
          href: canonical,
        },
      ]}
    />
  )
}
