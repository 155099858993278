import React from 'react'
import styled from '@emotion/styled'
import '../../assets/css/normalize.css'
import '../../assets/css/layout.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import {
  faGithub,
  faTwitter,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

library.add(
  faChevronLeft,
  faChevronRight,
  faGithub,
  faTwitter,
  faFacebookSquare
)

dayjs.locale('ja')

const Layout = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

export default ({ children }) => <Layout>{children}</Layout>
